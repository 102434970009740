import React from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBCheckbox,
  MDBIcon
}
from 'mdb-react-ui-kit';
import logo from '../image/logot (1).png'

function Login() {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    
      const handleSubmit = () => {
  
      if (email === 'cnmiass@gmail.com' && password === '123') {
        
       sessionStorage.setItem('login','14fsafskajshjkdashdbnasb8q7234y21hbjsandas')
       window.location.reload()
      } else {
        alert('Email or password is incorrect.');
      }
   };
  return (
    <MDBContainer fluid className='p-4 background-radial-gradient overflow-hidden vh-100'>

      <MDBRow className='h-100'>

        <MDBCol md='6' className='text-center text-md-start d-flex flex-column justify-content-center'>
{/* 
          <h1 className="my-5 display-3 fw-bold ls-tight px-3" style={{color: 'hsl(218, 71%, 45%)'}}>
            The best offer <br />
            <span style={{color: 'hsl(218, 41%, 15%)'}}>CHERLAPALLY NOTIFIED MUNICIPAL INDUSTRIAL AREA SERVICE SOCIETY</span>
          </h1> */}

          <h1 className="my-5 display-3 fw-bold" style={{color: 'hsl(218, 41%, 15%)', fontSize:'3rem'}}>
          CHERLAPALLY  <br/>
          <span style={{color: 'hsl(218, 41%, 15%)'}}> NOTIFIED</span> <br/>
          
            <span style={{color: 'hsl(218, 41%, 15%)'}}> MUNICIPAL INDUSTRIAL AREAS SERVICE SOCIETY</span>
          </h1>

         
        </MDBCol>

        <MDBCol md='4' className='position-relative'>

          <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
          <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>

          <MDBCard className='my-5 bg-glass'>
            <MDBCardBody className='p-5'>
            <img src={logo} alt="Your Image" style={{ maxHeight: '120px', width: '120px',marginLeft:100 }} />
            <p className='my-3 text-center fw-bold'>Login</p>

              <MDBInput wrapperClass='mb-4' label='Email' id='form3' onChange={(e) => setEmail(e.target.value)} type='email'/>
              <MDBInput wrapperClass='mb-4' label='Password' id='form4' onChange={(e) => setPassword(e.target.value)} type='password'/>

              <MDBBtn className='w-100 mb-4' size='md' onClick={handleSubmit}>Submit</MDBBtn>
            </MDBCardBody>
          </MDBCard>

        </MDBCol>

      </MDBRow>

    </MDBContainer>
  );
}

export default Login;