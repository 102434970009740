import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import TablePagination from '@mui/material/TablePagination';
import {
  MDBContainer,
  MDBNavbar,
  MDBBtn,
  MDBInputGroup,
  MDBIcon,
  MDBCollapse,MDBFile,MDBTypography
} from 'mdb-react-ui-kit';
import Swal from 'sweetalert2';

import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBCheckbox
} from 'mdb-react-ui-kit';


export default function MemberList() {
  const [page, setPage] = React.useState(0);
  
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [data, setData] = React.useState([])

  const  fetchMemberList = () => {
    axios.post('https://cnmiassapi.emedha.in/memberlist/v1/api/getall')
    .then((res => {
      console.log(res.data);
      setData(res.data.data)
    }))
  }

  useEffect(() => {
    fetchMemberList()
  }, [])

  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpen1, setIsOpen1] = React.useState(false);
  const [isOpenu, setIsOpenu] = React.useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);
  const toggleOpen1 = () => setIsOpen1(!isOpen1);
  const toggleOpenu = () => setIsOpenu(!isOpenu);

  const [ass, setAss] = useState('')
  const [cname, setCname] = useState('')
  const [plot, setPlot] = useState('')
  const [phase, setPhase] = useState('')
  const [sect, setSect] = useState('')
  const [cper, setCper] = useState('')
  const [cnum, setCnum] = useState('')
  const [tdue, setTdue] = useState('')

  const viewdate = (id) => {
    axios.post('https://cnmiassapi.emedha.in/memberlist/v1/api/getbyid',{id})
    .then((res => {
      setAss(res.data.assess)
      setCname(res.data.companyname)
      setPlot(res.data.plotno_shedno)
      setPhase(res.data.phase)
      setSect(res.data.sector)
      setCper(res.data.contact_per)
      setCnum(res.data.contact_num)
      setTdue(res.data.Totaldue)
    }))
  }

  const [formValue, setFormValue] = React.useState({
    assess: '',
    companyname: '',
    plotno_shedno: '',
    phase: '',
    sector: '',
    contact_per: '',
    contact_num: '',
    Totaldue: '',
  });

  const onChange = (e: any) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };



  

  const postmember = () => {
    axios.post('https://cnmiassapi.emedha.in/memberlist/v1/api/addmember',formValue)
    .then((res) => {
      if (res.data.status === 200) {
          toggleOpen();
          Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Data submitted successfully!',
          }).then(() => {
            window.location.reload();
        });
      }
  })
  .catch((error) => {
      console.error('Error:', error);
  });
  }

  const[bfile,setBfile] = useState('')
  const bulkuploadhandle = () => {
    const data = new FormData()
    data.append("excel", bfile)
    axios.post('https://cnmiassapi.emedha.in/memberlist/v1/api/addmemberbulk',data)
    .then((res) => {
      if (res.data.status === 200) {
          toggleOpen1();
          Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Data submitted successfully!',
          }).then(() => {
            window.location.reload();
        });
      }
  })
  .catch((error) => {
      console.error('Error:', error);
  });
  }

  const handleDelete = (id) => {
   axios.post('https://cnmiassapi.emedha.in/memberlist/v1/api/remmember',{id})
   .then((res => {
     if(res.data.status === 200){
      fetchMemberList()
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
        if (result.isConfirmed) {
            Swal.fire(
                'Deleted!',
                'Your data has been deleted.',
                'success'
            );
        }
    });
     }
   }))
};

const [id, setId] =  useState("");

const editmember = () => {
  axios.post('https://cnmiassapi.emedha.in/memberlist/v1/api/membedit',{assess:ass, companyname:cname, plotno_shedno:plot, phase:phase, sector:sect, contact_per:cper, contact_num:cnum, Totaldue:tdue,id})
  .then((res) => {
    if (res.data.status === 200) {
        toggleOpenu();
        fetchMemberList()
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Edited submitted successfully!',
        })
    }
})
.catch((error) => {
    console.error('Error:', error);
});
}

const blockAlert = () => {
  Swal.fire({
      icon: 'warning',
      title: "Warning !",
      text: "Are you sure to Delete all the data ?",
      confirmButtonColor: "rgb(126, 110, 228)",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      confirmButtonText: "Yes"
  }).then((result) => {
      if (result.value) {
        basicDataAlert()
      }
  })
}

const basicDataAlert = () => {
      axios.post('https://cnmiassapi.emedha.in/memberlist/v1/api/alldataclearmember')
      .then((res) =>{
        if(res.data.status === 200){
          Swal.fire({
            icon: 'success',
            title: "Successfull !",
            text: "Data is Deleted successfully !",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok"
        }).then((result) => {
            if (result.value) {
                return
            }
        })
        }
      })
}

const handlerset = () => {
  axios.post('https://cnmiassapi.emedha.in/memberlist/v1/api/memreset')
  .then((res) => {
      if(res.status === 200){
      console.log('Reseted', res.data);
      }
  }).catch((err)=>console.error(err))
}

  return (
       <div>
      <Box sx={{ width: '100%'}}>
      <MDBTypography tag='h5' style={{textAlign:'center', marginBottom:18}}>Member List</MDBTypography>
      <Paper sx={{ width: '100%', mb: 2 }}>

      <MDBNavbar dark bgColor='dark' >
      <MDBContainer fluid>
        <MDBInputGroup  className='d-flex w-auto mb-2' >
          <input className='form-control' placeholder="Search..." aria-label="Search" type='Search' />
          <MDBBtn outline >Search</MDBBtn>
          <MDBBtn rounded className='mx-3' color='secondary'  onClick={toggleOpen}>
        + Add
      </MDBBtn>
      
        </MDBInputGroup>
        <div>
        <MDBBtn rounded className='mx-3' color='secondary' onClick={handlerset}  >
        Reset
      </MDBBtn>
        <MDBBtn rounded className='mx-3' color='secondary'  onClick={toggleOpen1}>
        Bulk upload
      </MDBBtn>
      <MDBBtn rounded className='mx-3' color='secondary'  onClick={blockAlert}>
        Delete All
      </MDBBtn>

        </div>
        
      </MDBContainer >
      
      
      {/* <MDBContainer className='justify-content-end'>
          <MDBBtn outline >Search</MDBBtn>
      </MDBContainer> */}
    </MDBNavbar>

    <MDBCollapse open={isOpen}>
    <MDBContainer fluid className='my-4' >
      <MDBValidation className='row g-3'>
      <MDBValidationItem className='col-md-2'>
        <MDBInput
          name='assess'
          onChange={onChange}
          id='validationCustom01'
          required
          label='Assess'
        />
      </MDBValidationItem>
      <MDBValidationItem className='col-md-4'>
        <MDBInput
          name='companyname'
          onChange={onChange}
          id='validationCustom02'
          required
          label='Company name'
        />
      </MDBValidationItem>
      <MDBValidationItem className='col-md-4'>
        <MDBInput
          name='plotno_shedno'
          onChange={onChange}
          id='validationCustom02'
          required
          label='Plot no / SHED no'
        />
      </MDBValidationItem>
     
      <MDBValidationItem className='col-md-6' feedback='Please provide a valid city.' invalid>
        <MDBInput
          // value={formValue.city}
          name='contact_per'
          onChange={onChange}
          id='validationCustom03'
          required
          label='Contact Person'
        />
      </MDBValidationItem>
      <MDBValidationItem className='col-md-4' feedback='Please provide a valid zip.' invalid>
        <MDBInput
          // value={formValue.zip}
          name='contact_num'
          onChange={onChange}
          id='validationCustom05'
          required
          label='Contact Number'
        />
      </MDBValidationItem>
      <MDBValidationItem className='col-md-3' feedback='Please provide a valid zip.' invalid>
        <MDBInput
          // value={formValue.zip}
          name='phase'
          onChange={onChange}
          id='validationCustom05'
          required
          label='Phase'
        />
      </MDBValidationItem>
      <MDBValidationItem className='col-md-3' feedback='Please provide a valid zip.' invalid>
        <MDBInput
          // value={formValue.zip}
          name='sector'
          onChange={onChange}
          id='validationCustom05'
          required
          label='Sector'
        />
      </MDBValidationItem>
      <MDBValidationItem className='col-md-3' feedback='Please provide a valid zip.' invalid>
        <MDBInput
          // value={formValue.zip}
          name='Totaldue'
          onChange={onChange}
          id='validationCustom05'
          required
          label='Total Due'
        />
      </MDBValidationItem>
     
      <div className='col-12'>
        <MDBBtn type='submit' style={{marginBottom:30}} onClick={postmember}>Submit form</MDBBtn>
      </div>
    </MDBValidation>
    </MDBContainer>
      </MDBCollapse>

      <MDBCollapse open={isOpenu}>
    <MDBContainer fluid className='my-4' >
        <MDBContainer className='row g-3'>
            <div className='col-md-2'>
                <MDBInput
                    label='Assess'
                    value={ass}
                    onChange={(e) =>setAss(e.target.value)}
                />
            </div>
            <div className='col-md-4'>
                <MDBInput
                    onChange={(e) =>setCname(e.target.value)}
                    label='Company name'
                    value={cname}
                />
            </div>
            <div className='col-md-4'>
                <MDBInput
                    onChange={(e) =>setPlot(e.target.value)}
                    label='Plot no / SHED no'
                    value={plot}
                />
            </div>

            <div className='col-md-6'>
                <MDBInput
                    onChange={(e) =>setCper(e.target.value)}
                    label='Contact Person'
                    value={cper}
                />
            </div>
            <div className='col-md-4'>
                <MDBInput
                    onChange={(e) =>setCnum(e.target.value)}
                    label='Contact Number'
                    value={cnum}
                />
            </div>
            <div className='col-md-3'>
                <MDBInput
                    onChange={(e) =>setPhase(e.target.value)}
                    label='Phase'
                    value={phase}

                />
            </div>
            <div className='col-md-3'>
                <MDBInput
                    onChange={(e) =>setSect(e.target.value)}
                    label='Sector'
                    value={sect}
                />
            </div>
            <div className='col-md-3'>
                <MDBInput
                    onChange={(e) =>setTdue(e.target.value)}
                    
                    label='Total Due'
                    value={tdue}
                />
            </div>

            <div className='col-12'>
                <MDBBtn type='submit' style={{ marginBottom: 10 }} onClick={editmember}>Update form</MDBBtn>
            </div>
        </MDBContainer>
    </MDBContainer>
</MDBCollapse>

<MDBCollapse open={isOpen1}>
    <MDBContainer fluid className='my-4' >
      <MDBValidation className='row g-3'>
      <MDBValidationItem className='col-md-6'>
      <MDBFile label='Choose excel file to upload bulk data' size='lg' id='formFileSm' onChange={(e)=>setBfile(e.target.files[0])} />
      </MDBValidationItem>
      
     
      <div className='col-12'>
        <MDBBtn type='submit' style={{marginBottom:30}} onClick={bulkuploadhandle}>Upload</MDBBtn>
      </div>
    </MDBValidation>
    </MDBContainer>
      </MDBCollapse>


    

          <TableContainer className='my-3'>
         <Table >
      
         <TableHead sx={{ backgroundColor: 'lightgray' }}>
         <TableRow>
              
           <TableCell
           
             style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
           >
             S.No
           </TableCell>
           <TableCell

           
             style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
           >
             Assess 
           </TableCell>
           <TableCell

           
             style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
           >
             PtiNo 
           </TableCell>
           <TableCell

            
             style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
           >
             Company Name
           </TableCell>
           <TableCell

             
             style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
           >
             Plot no / SHED no
           </TableCell>
           <TableCell

             
             style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
           >
             Phase
           </TableCell>
           <TableCell

             
             style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
           >
             Sector
           </TableCell>
           <TableCell

             
             style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
           >
             Contact Person
           </TableCell>
           <TableCell

             
             style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
           >
             Contact Number
           </TableCell>
           <TableCell

             
             style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
           >
             Total Due
           </TableCell>
           <TableCell

             
             style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
           >
             Action
           </TableCell>
           
         </TableRow>
       </TableHead>
       <TableBody>
                {
                  data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map( (row) => {
                    return (
                      <TableRow key={row.slno}>
                          
                 <TableCell 
                   style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.slno}</TableCell>
                 <TableCell 
                   style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.assess}</TableCell>
                 <TableCell 
                   style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.ptino}</TableCell>
                 <TableCell 
                   style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.companyname}</TableCell>
                 <TableCell 
                   style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.plotno_shedno}</TableCell>
                 <TableCell 
                   style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.phase}</TableCell>
                 <TableCell 
                   style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.sector}</TableCell>
                 <TableCell 
                   style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.contact_per}</TableCell>
                 <TableCell 
                   style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.contact_num}</TableCell>
                 <TableCell 
                   style={{ top: 57, minWidth: 30, fontSize: 12 }}>{row.Totaldue}</TableCell>
                 <TableCell 
                   style={{ top: 57, minWidth: 30, fontSize: 13,  }}>
                    <MDBIcon far icon="edit" onClick={() => {
                      toggleOpenu()
                      viewdate(row.slno)
                      setId(row.slno)
                    }}/> &nbsp; &nbsp;
                    <MDBIcon far icon="trash-alt" onClick={() => handleDelete(row.slno)} />
                   </TableCell>

                      </TableRow>
                    )
                })
              }
       </TableBody>
   </Table>
  </TableContainer>
  <TablePagination
     rowsPerPageOptions={[5, 10, 25]}
     component="div"
     count={data.length}
     rowsPerPage={rowsPerPage}
     page={page}
     onPageChange={handleChangePage}
     onRowsPerPageChange={handleChangeRowsPerPage}
   />
 
 </Paper>
 </Box>
    </div>
  )
}
