import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import Form from 'react-bootstrap/Form';
import ListSubheader from '@mui/material/ListSubheader';
import InputLabel from '@mui/material/InputLabel';
import axios from "axios";



export default function Createtemplate() {
  const [header, setHeader] = React.useState("");

  const [isActive, setIsActive] = React.useState(false);
  const [isActive1, setIsActive1] = React.useState(false);
  const [isActive2, setIsActive2] = React.useState(false);
  const [isActive3, setIsActive3] = React.useState(false);
  

  const handleClick = () => {
    setIsActive(!isActive);
  };
  const handleClick1 = () => {
    setIsActive1(!isActive1);
  };
  const handleClick2 = () => {
    setIsActive2(!isActive2);
  };
  const handleClick3 = () => {
    setIsActive3(!isActive3);
  };

  const [formData, setFormData] = React.useState('');
  const [viewData, setViewData] = React.useState('');
  

  // const handleViewClick = () => {
  //   const dataArr = formData.split(',');
  //   if (dataArr.length === 4) {
  //     const template = "Dear {{1}}, your invoice no {{2}} dated {{3}} generated. Please make payment through {{4}} account . Ignore if paid.";
  //     const replacedTemplate = template.replace(/{{(\d+)}}/g, (_, number) => dataArr[number - 1].trim());
  //     setViewData(replacedTemplate);
  //     setBmg(replacedTemplate)
  //   } else {
      
  //     alert('Invalid input. Please enter data separated by commas.');
  //   }
  // };
  const [text, setText] = React.useState('');
  const [charCount, setCharCount] = React.useState(0);

  const handleChange = (e) => {
    // setFormData(e.target.value);
    const newText = e.target.value;
    setText(newText);
    setCharCount(newText.length);
    setBdyexp(newText)
    setBmg(newText)
  };

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    axios.post('https://chatbotapi.emedha.in/ebot/emedha/templang/get-all')
    .then((res => {
      console.log("rbot", res.data);
      setData(res.data)
      
    }))
    .catch((err => {
      console.error(err)
    }))

    getCat()

  },[])

  const [catget, setCatget] = React.useState([])
    
  const getCat = () => {
    axios.post('https://chatbotapi.emedha.in/ebot/emedha/tempcatge/get-all')
    .then((res => {
      console.log("rbot", res.data);
      setCatget(res.data)
      
    }))
    .catch((err => {
      console.error(err)
    }))
  }

  const [tempname, setTempname] = React.useState('')
  const [lang, setLang] = React.useState('')
  const [categ, setCateg] = React.useState('')
  const [bdyexp, setBdyexp] = React.useState('')
  const [bmsg, setBmg] = React.useState('')
  const [btphntxt, setBtphtxt]= React.useState('') 
  const [btnphact, setBtnphact] = React.useState(false)
  const [bturltxt, setBturltxt]= React.useState(false) 
  const [btnurlact, setBtnurlact] = React.useState('')
  const [btnaction, setBtnaction] = React.useState('') 
  const [cfoot, setCfoot] = React.useState('') 
  const [chead, setChead] = React.useState('') 


  const handlesubmit = () => {
    // console.log({tname:tempname,tlang:lang,tcategory:categ,body_example:bdyexp,tbody:bmsg,btn_ph_text:btphntxt,btn_ph_action:btnphact,btn_url_text:bturltxt,btn_url_action:btnurlact});
    console.log({tname:tempname,tlang:lang,tcategory:categ,tbody:bmsg,tfooter:cfoot,theader:chead,button_call:btphntxt,button_url:btnurlact});
    axios.post('https://emedha.com/cnmiass/ctemplate.php',
    {tname:tempname,tlang:lang,tcategory:categ,tbody:bmsg,tfooter:cfoot,theader:chead,bcall:btphntxt,burl:btnurlact})
    .then((res => {
      console.log(res.data);
      alert('added  successfully');
      
    }))
  }

  const bynacthandle = (e) => {
    const ab = e.target.value
    setBtnaction(ab);
    if(ab === 'call'){
      setBtnphact(true)
      // setBturltxt(false)
      // setBturltxt('')
    }else if (ab === 'visit'){
      // setBtnphact('https://emedha.com')
       setBturltxt(true)
      //  setBtnphact(false)
       
    }
  };


  // const bynacthandle = (e) => {
  //   const ab = e.target.value
  //   setBtnaction(ab);
  //   if(ab === 'call'){
  //     setBtnphact("919849164361")
  //     console.log('hhh');
  //     setBtphtxt('call')
  //     setBtnurlact('')
  //     setBturltxt('')
  //   }else if (ab === 'visit'){
  //     setBtnphact('https://emedha.com')
  //      setBturltxt('Visit')
  //      setBtnphact('')
  //      setBtphtxt('')
  //   }else {
  //     alert('error btn')
  //   }
  // };

 


  
  return (
    <Box>
      <Box>
        <Paper sx={{ m: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
            <Box>
              <TextField
                id="outlined-basic"
                label="Template name"
                variant="outlined"
                onChange={(e)=>{
                  setTempname(e.target.value)
                  console.log(e.target.value)
                }}
                sx={{
                    padding:'10px',
                  "& .MuiInputBase-input": {
                    height: 30, 
                    padding: "5px 10px",
                  },
                }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  color: "grey",
                  fontWeight: "300",
                  fontSize: 14,
                  alignItems: "center",
                }}
               
              >
                Lang
              </Typography>
              <FormControl sx={{ m: 1, minWidth: 80 }}>
                <Select
                  displayEmpty
                  sx={{
                    height: 30,
                    borderColor: "grey",
                    "&:focus": {
                      borderBlockColor: "grey !important",
                    },
                  }}
                  onChange={(e) => {
                    setLang(e.target.value)
                    console.log(e.target.value);
                  }}
                 
                >
                 
                  {
                    data.map((item) => {
                      return (<MenuItem key={item.sno} value={item.code}>{item.code}</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  color: "grey",
                  fontWeight: "300",
                  fontSize: 14,
                  alignItems: "center",
                }}
              >
                category
              </Typography>
              <FormControl sx={{ m: 1, minWidth: 80 }}>
                <Select displayEmpty sx={{ height: 30 }}
                 onChange={(e) => {
                  setCateg(e.target.value)
                
                }}
                >
                {
                    catget.map((item) => {
                      return (<MenuItem key={item.sno} value={item.name}>{item.name}</MenuItem>)
                    })
                  }
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Paper>
      </Box>

      <Paper sx={{ m: 2 }}>
        <Box sx={{height: 1000}}>

           <Box sx={{m:3}}> 
           <Box sx={{height: 68,display:'flex',justifyContent:'space-between',borderBottom:'3px solid grey',padding:2}}>
        <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  color: "grey",
                  fontWeight: "300",
                  fontSize: 20,
                  alignItems: "center",
                }}
              >
                
              </Typography>
          {/* <Button variant="outlined" size="medium" onClick={handlesubmit}>
          Submit
        </Button> */}
        </Box>
           </Box>
            {/* header */}
           <Box sx={{m:3}}>
                <Box sx={{display:'flex'}}>
                <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  color: "black",
                  fontWeight: "500",
                  fontSize: 18,
                  alignItems: "center",
                }}
              >
                Header
              </Typography>
                <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  color: "grey",
                  fontWeight: "300",
                  fontSize: 10,
                  marginLeft:2,
                 
                  
                  
                }}
              >
                Optinal
              </Typography>
                </Box>

                <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  color: "grey",
                  fontWeight: "300",
                  fontSize: 11,
                 
                }}
              >
             Add a title or choose which type of media you'll use for this header
              </Typography>
                
                <Box sx={{marginTop:2}}>
                <FormControl sx={{  minWidth: 90 }}>
                <Select
                  displayEmpty
                  sx={{
                    height: 35,
                    borderColor: "grey",
                    "&:focus": {
                      borderBlockColor: "grey !important",
                    },
                  }}
                  onChange={(e) => setHeader(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="text">Text</MenuItem>
                  <MenuItem value="media">Media</MenuItem>
                 
                </Select>
              </FormControl>
              
                </Box>
                {
                  header === 'text' && (
                    <div  style={{marginTop:10}}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Control type="text" placeholder="Enter text in English (US)" style={{ width: 700,height:40 }} onChange={(e) => setChead(e.target.value)} />
                   </Form.Group>
                    </div>
                  )
                }

                {
                  header === 'media' && (
                    <div style={{display:'flex'}}>
                      <div
      style={{
        width: '100px',
        height: '100px',
        margin: '10px',
        border: `2px solid ${isActive ? 'blue' : 'black'}`,
        borderRadius: `10px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        cursor: 'pointer',
      }}
      onClick={handleClick}
    >
      <div style={{ zIndex: '1' }}>image</div>
      
        <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
          <input type="checkbox" />
        </div>
      
    </div>

    <div
      style={{
        width: '100px',
        height: '100px',
        margin: '10px',
        border: `2px solid ${isActive1 ? 'blue' : 'black'}`,
        borderRadius: `10px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        cursor: 'pointer',
      }}
      onClick={handleClick1}
    >
      <div style={{ zIndex: '1' }}>video</div>
      
        <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
          <input type="checkbox" />
        </div>
      
    </div>

    <div
      style={{
        width: '100px',
        height: '100px',
        margin: '10px',
        border: `2px solid ${isActive2 ? 'blue' : 'black'}`,
        borderRadius: `10px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        cursor: 'pointer',
      }}
      onClick={handleClick2}
    >
      <div style={{ zIndex: '1' }}>Document</div>
      
        <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
          <input type="checkbox" />
        </div>
      
    </div>

    <div
      style={{
        width: '100px',
        height: '100px',
        margin: '10px',
        border: `2px solid ${isActive3 ? 'blue' : 'black'}`,
        borderRadius: `10px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        cursor: 'pointer',
      }}
      onClick={handleClick3}
    >
      <div style={{ zIndex: '1' }}>Location</div>
      
        <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
          <input type="checkbox" />
        </div>
      
    </div>
                    </div>
                  )
                }
           </Box>

           {/* body */}

           <Box sx={{m:3}}>
                <Box sx={{display:'flex'}}>
                <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  color: "black",
                  fontWeight: "500",
                  fontSize: 18,
                  alignItems: "center",
                }}
              >
                Body
              </Typography>
                </Box>

                <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  color: "grey",
                  fontWeight: "300",
                  fontSize: 11,
                 
                }}
              >
             Enter the text for your message in the language you've selected
              </Typography>
              
             <div className="my-3" style={{marginTop:18}}>
             <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Control as="textarea" maxLength={1024}  placeholder="Dear {{1}}, your invoice no {{2}} dated {{3}} generated. Please make payment through {{4}} account . Ignore if paid." rows={8}  cols={100} onChange={handleChange}  />
            <p>Character Count: {charCount}/1024</p>
            </Form.Group>
                {/* <Button onClick={handleViewClick}>View</Button> */}
             </div>
             <div>
            <p>{viewData}</p>
          </div>
           </Box>
            
            {/* footer */}

            <Box sx={{m:3}}>
                <Box sx={{display:'flex'}}>
                <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  color: "black",
                  fontWeight: "500",
                  fontSize: 18,
                  alignItems: "center",
                }}
              >
                Footer
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  color: "grey",
                  fontWeight: "300",
                  fontSize: 10,
                  marginLeft:2,
                 
                  
                  
                }}
              >
                Optinal
              </Typography>
                </Box>

                <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  color: "grey",
                  fontWeight: "300",
                  fontSize: 11,
                 
                }}
              >
             Add a short line of text to the bottom of your message template
              </Typography>
              
             <div  style={{marginTop:10}}>
             <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
             <Form.Control type="text" placeholder="Enter text in English (US)" style={{ width: 700,height:40 }} onChange={(e) => setCfoot(e.target.value)} />
            </Form.Group>
             </div>
           </Box>
             
             {/* Button */}

             <Box sx={{m:3}}>
                <Box sx={{display:'flex'}}>
                <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  color: "black",
                  fontWeight: "500",
                  fontSize: 18,
                  alignItems: "center",
                }}
              >
                Button
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  color: "grey",
                  fontWeight: "300",
                  fontSize: 10,
                  marginLeft:2,
                 
                  
                  
                }}
              >
                Optinal
              </Typography>
                </Box>

                <Typography
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  color: "grey",
                  fontWeight: "300",
                  fontSize: 11,
                 
                }}
              >
             Call to action buttons
              </Typography>
              
               <FormControl variant="filled" sx={{minWidth: 120 }}>
        <InputLabel htmlFor="grouped-select">+ Add a button</InputLabel>
        <Select defaultValue="" id="grouped-select" label="Grouping" sx={{height:50, maxWidth:400}}  onChange={bynacthandle}>
          <MenuItem value='call'>call</MenuItem>
          <MenuItem value='visit'>Visit website</MenuItem>

          
        </Select>
      </FormControl>
      {
        btnphact && (
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
             <Form.Control type="text" placeholder="Enter Number" style={{ width: 700,height:40,marginTop:20 }} onChange={(e) => setBtphtxt(e.target.value)} />
            </Form.Group>
        )
      } 

      {
        bturltxt && (
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
             <Form.Control type="text" placeholder="Enter website url" style={{ width: 700,height:40}} onChange={(e) => setBtnurlact(e.target.value)} />
            </Form.Group>
        )
      }
           </Box> 
           <div style={{display:'flex', justifyContent:'flex-end',marginRight:50}}>
           <Button variant="outlined" size="medium" onClick={handlesubmit}>
          Submit
        </Button>
           </div>
        </Box>
      </Paper>
    </Box>
  );
}
