import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Menu, MenuItem } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import Swal from "sweetalert2";
import MemberList from '../MemberList/MemberList';
import { Link, Route, Routes } from 'react-router-dom';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import TemplatesList from '../Templates/TemplatesList';
import Sendmessagelist from '../MemberList/Sendmessagelist';
import Messagestatus from '../MemberList/Messagestatus';
import Createtemplate from '../MemberList/Createtemplate';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import Collapse from '@mui/material/Collapse';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import UploadIcon from '@mui/icons-material/Upload';
import Roshan from '../MemberList/Roshan';
import Techie from '../MemberList/Techie';
import Niala from '../MemberList/Niala';
import Messagedetails from '../MemberList/Messagedetails';


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function DashboardHome() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  
  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = ()=>{
    Swal.fire({
      title: `Are you sure you want to logout?`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      
      if(result.isConfirmed){
        window.location.reload()
        sessionStorage.removeItem('login');
      }
      
      
    });
}


const [openlist, setOpenlist] = React.useState(false);

  const handleClicklist = () => {
    setOpenlist(!openlist);
  };

  const [openc, setOpenc] = React.useState(false);

  const handleClickc = () => {
    setOpenc(!openc);
  };
  const [opencm, setOpencm] = React.useState(false);

  const handleClickcm = () => {
    setOpencm(!opencm);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{backgroundColor:'white'}}>
        <Toolbar>
          <IconButton
            color="black"
            aria-label="open drawer"
            onClick={()=>{setOpen(!open)}}
            edge="start"
            sx={{
                marginRight: 5,
                ...(open && { display: 'block',color:'black' }),
              }}
            
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6"  component="div" sx={{color:'black',flex:1,fontWeight:'bold',fontSize:14}}>
           Welcome  to the Dashboard! &nbsp;
          </Typography>
          {auth && (
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                
              >
                <AccountCircle style={{color:'black'}}/>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>

        {
          open && (
            <div style={{ height: '20%',textAlign:'center',paddingTop:8}}> {/* Responsive height */}
            <h3 style={{fontFamily:'serif',textShadow: '2px 2px 4px rgba(0,0,0,0.3)',fontWeight:'bold' }}>CNMIASS</h3>
        </div>
          )
        }

        <Divider />

        <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
     
    >
     
     
      <ListItemButton onClick={handleClickc}>
        <ListItemIcon>
          <UploadIcon />
        </ListItemIcon>
        <ListItemText primary="Data  Upload" />
        {openc ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openc} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <Link to='/' style={{ color: 'black', textDecoration: 'none' }} >
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <RadioButtonCheckedIcon />
            </ListItemIcon>
            <ListItemText primary="Roshi" />
          </ListItemButton>
          </Link>
        </List>
        <List component="div" disablePadding>
        <Link to='/hitech' style={{ color: 'black', textDecoration: 'none' }} >
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <RadioButtonCheckedIcon />
            </ListItemIcon>
            <ListItemText primary="Hitech" />
          </ListItemButton>
          </Link>
        </List>
        <List component="div" disablePadding>
        <Link to='/memberlist' style={{ color: 'black', textDecoration: 'none' }} >
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <RadioButtonCheckedIcon />
            </ListItemIcon>
            <ListItemText primary="Member List" />
          </ListItemButton>
          </Link>
        </List>
        <List component="div" disablePadding>
        <Link to='/iala' style={{ color: 'black', textDecoration: 'none' }} >
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <RadioButtonCheckedIcon />
            </ListItemIcon>
            <ListItemText primary="IALA" />
          </ListItemButton>
          </Link>
        </List>
      </Collapse>
    </List>


             <Link to='/sendmsg' style={{ color: 'black', textDecoration: 'none' }} >
            <ListItem  disablePadding sx={{ display: 'block'}}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <MarkEmailUnreadIcon />
                </ListItemIcon>
                <ListItemText primary='Send Message' sx={{ opacity: open ? 1 : 0 }} />
                
              </ListItemButton>
            </ListItem>
            </Link>
             <Link to='/createtemplate' style={{ color: 'black', textDecoration: 'none' }} >
            <ListItem  disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <AddCircleIcon />
                </ListItemIcon>
                <ListItemText primary='Create Templates' sx={{ opacity: open ? 1 : 0 }} />
                
              </ListItemButton>
            </ListItem>
            </Link>
             <Link to='/template' style={{ color: 'black', textDecoration: 'none' }} >
            <ListItem  disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <LibraryBooksIcon />
                </ListItemIcon>
                <ListItemText primary='Templates' sx={{ opacity: open ? 1 : 0 }} />
                
              </ListItemButton>
            </ListItem>
            </Link>
             {/* <Link to='/msgstatus' style={{ color: 'black', textDecoration: 'none' }} >
            <ListItem  disablePadding sx={{ display: 'block'}}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <LiveHelpIcon />
                </ListItemIcon>
                <ListItemText primary='Message Status' sx={{ opacity: open ? 1 : 0 }} />
                
              </ListItemButton>
            </ListItem>
            </Link> */}

            <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
     
    >
     
     
      <ListItemButton onClick={handleClickcm}>
        <ListItemIcon>
        <LiveHelpIcon />
        </ListItemIcon>
        <ListItemText primary="Message Status" />
        {opencm ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={opencm} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <Link to='/details' style={{ color: 'black', textDecoration: 'none' }} >
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <RadioButtonCheckedIcon />
            </ListItemIcon>
            <ListItemText primary="Details" />
          </ListItemButton>
          </Link>
        </List>
        <List component="div" disablePadding>
        <Link to='/msgstatus' style={{ color: 'black', textDecoration: 'none' }} >
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <RadioButtonCheckedIcon />
            </ListItemIcon>
            <ListItemText primary="Count" />
          </ListItemButton>
          </Link>
        </List>
      </Collapse>
    </List>


           
          
           
        </List>
        <List>
         
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div style={{ marginTop: 70 }} >
            <Routes>
            <Route path='/memberlist' element={<MemberList/>}></Route> 
            <Route path='/template' element={<TemplatesList/>}></Route> 
            <Route path='/sendmsg' element={<Sendmessagelist/>}></Route> 
            <Route path='/msgstatus' element={<Messagestatus/>}></Route> 
            <Route path='/createtemplate' element={<Createtemplate/>}></Route> 
            <Route path='/' element={<Roshan/>}></Route> 
            <Route path='/hitech' element={<Techie/>}></Route> 
            <Route path='/iala' element={<Niala/>}></Route> 
            <Route path='/details' element={<Messagedetails/>}></Route> 
            </Routes>       
      </div>
      </Box>
    </Box>
  );
}