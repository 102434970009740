import { BrowserRouter } from 'react-router-dom';
import './App.css';
import DashboardHome from './components/Dashboard/DashboardHome';
import Login from './components/login/Login';

function App() {
  return (
    <div >
     
      <BrowserRouter>
      {
        sessionStorage.getItem('login') && (
          <DashboardHome/>
        )
      }

      {
        !sessionStorage.getItem('login') && (
          <Login/>
        )
      }
      </BrowserRouter>
      
    </div>
  );
}

export default App;
