import React, { useState } from 'react'
import {
    MDBContainer,
    MDBNavbar,
    MDBBtn
  } from 'mdb-react-ui-kit';

  import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Slide from '@mui/material/Slide';
import {  InputBase, Button } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


export default function Sendmessagelist() {


  const [imgFile, setImgFile] = useState(null);
  const [description, setDescription] = useState('');

  const handleFileChange = (e) => {
    setImgFile(e.target.files[0]);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };


  // http://localhost:3005/upload

  const handleSubmitImgForm = async (e) => {
    e.preventDefault();
  
    try {
      const formData = new FormData();
      formData.append('image', imgFile); // Append the selected image file
      formData.append('description', description); // Append the description entered by the user
  
      const response = await axios.post('https://api.emedha.in/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data' // Ensure correct content type for FormData
        }
      });
  
      console.log('Upload response:', response.data);
      // Optionally, handle success (e.g., show success message to user)
    } catch (error) {
      console.error('Upload error:', error);
      // Optionally, handle error (e.g., show error message to user)
    }
  };
  













    const[tab, setTab] = React.useState([])

    React.useEffect(()=>{
        axios.post(`https://emedha.com/cnmiass/get-template-status.php?limit=10`)
        .then(res => {
            console.log(res.data.data);
            setTab(res.data.data)

        })

        
    },[])

    const [coldata, setColdata] = useState([])

    const displaycolumns = () => {
      axios.post('https://cnmiassapi.emedha.in/memberlist/v1/api/listofcolms')
      .then((res) => {
        console.log("sasdasd", res.data.data)
        setColdata(res.data.data)
      })
    }

    React.useEffect(() => {
      displaycolumns()
    },[])


    // https://emedha.com/cnmiass/sendgreet.php

    const [dtxt, setDtxt] = useState('')
    const [, secondPart] = dtxt.split('_'); 

    const handleSubmit = () => {
        // const [, secondPart] = text.split('_'); 
        console.log(secondPart + " is selected");

        if(secondPart === "fees"){
            axios.post('https://emedha.com/cnmiass/senddues.php')
            .then((res) => {
                if(res.status === 200){
                console.log('Dues message sent!', res.data);
                }
            }).catch((err)=>console.error(err))
        }else if(secondPart === "annualtax") {
            axios.post('https://emedha.com/cnmiass/sendannualtax.php')
            .then((resa) => {
                if(resa.status === 200){
                console.log('annualtax message sent!', resa.data);
                }
            }).catch((err)=>console.error(err))
        }else if(secondPart === "tax") {
            axios.post('https://emedha.com/cnmiass/sendtax.php')
            .then((rest) => {
                if(rest.status === 200){
                console.log('tax message sent!', rest.data);
                }
            }).catch((err)=>console.error(err))
        }else if(secondPart === "greet") {
            axios.post('https://emedha.com/cnmiass/sendgreet.php')
            .then((rest) => {
                if(rest.status === 200){
                console.log('greet message sent!', rest.data);
                }
            }).catch((err)=>console.error(err))
        }else if(secondPart === "iala") {
          axios.post('https://emedha.com/cnmiass/sendtaxiala.php')
          .then((rest) => {
              if(rest.status === 200){
              console.log('iala message sent!', rest.data);
              }
          }).catch((err)=>console.error(err))
      }else if(dtxt === "cnmiass_members_dues") {
        axios.post('https://emedha.com/cnmiass/sendtax1.php')
        .then((rest) => {
            if(rest.status === 200){
            console.log('member_due message sent!', rest.data);
            }
        }).catch((err)=>console.error(err))
    }
    }

    // const handlerset = () => {
    //     axios.post('https://cnmiassapi.emedha.in/memberlist/v1/api/memreset')
    //     .then((res) => {
    //         if(res.status === 200){
    //         console.log('Reseted', res.data);
    //         }
    //     }).catch((err)=>console.error(err))
    // }

    const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

    const [dataviewtemp, setDataviewtemp] = useState([]);
    const[dlen, setDlen] = useState({ components: [{ example: { body_text: [[]] } }] })

    const handleviewtemp = (name) => {
        handleClickOpen()
        axios.post(`https://emedha.com/cnmiass/get-template.php?name=${name}`)
        .then(res => {
            console.log(res.data.data[0]);
            setDataviewtemp(res.data.data[0])
            setDlen(res.data.data[0])

        })
    }

    // const len = dataviewtemp.components?.[1]?.example?.body_text?.[0]?.length;
    // const len1 = Array.from({ len }, (_, index) => (
    //   <div key={index}>{index +1}</div>
    // ));

    // const renderPlaceholders = () => {
    //   const len = dataviewtemp.components?.[1]?.example?.body_text?.[0]?.length;
    //   if (len) {
    //     return Array.from({ length: len }, (_, index) => (
    //       <div key={index}>
    //         {index + 1}
    //         <select >
    //         <option disabled selected>--select--</option>
    //           {
    //             coldata.map((item, index) => {
    //               return <option key={index}>{item}</option>
    //             })
    //           }
    //         </select>
    //         </div>
    //     ));
    //   }
    //   return null;
    // };

    const renderPlaceholders = () => {
      if (dtxt === 'cnmiass_greet') {
        return (
          <div>
          <form onSubmit={handleSubmitImgForm} style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center' }}>
            
              <input type="file" accept="image/*" placeholder='Upload Image' onChange={handleFileChange} style={{ padding: '5px', border: '1px solid #ccc', borderRadius: '5px' }} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center' }}>
             
              <textarea value={description} placehodler="text" onChange={handleDescriptionChange} style={{ padding: '5px', border: '1px solid #ccc', borderRadius: '5px' ,width:'350px',height:'42px'}} rows="5"></textarea>
            </div>
            <button type="submit" style={{ padding: '10px', backgroundColor: '#428bca', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer',amrginTop:'20px' }}>Submit</button>
          </form>
        </div>
        
        
        );
      }
    
      // Render placeholders if dtxt is not "cnmiass_greet"
      const len = dataviewtemp.components?.[1]?.example?.body_text?.[0]?.length;
      if (len) {
        return Array.from({ length: len }, (_, index) => (
          <div key={index}>
            {index + 1}
            <select>
              <option disabled selected>--select--</option>
              {coldata.map((item, index) => (
                <option key={index}>{item}</option>
              ))}
            </select>
          </div>
        ));
      }
    
      return null;
    };
    

  return (
        <div>
      <Box sx={{ width: '100%'}}>
      <Paper sx={{ width: '100%', mb: 2 }}>
      <MDBNavbar dark bgColor='dark' >
      <MDBContainer fluid>
      <div style={{display:'flex'}}>
      <MDBContainer >
      <select className='form-select mb-2 my-1' style={{width:'300px'}} onChange={(e) => {
        setDtxt(e.target.value)
        handleviewtemp(e.target.value)
    }}>
        <option >-- select name --</option>
        {
            tab.map((data) => {
               return <option >{data.name}</option>
            })
        }
      </select>
      </MDBContainer >
      <div className='my-0'>
          {/* <MDBBtn outline className='my-1' >Send</MDBBtn> */}
      </div>
      </div>
         
      </MDBContainer >
    </MDBNavbar>
      </Paper>
      <React.Fragment>
      <Dialog
        open={open}
        maxWidth='lg'
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <TableContainer>

      
   
      
<Table >
   
    <TableHead sx={{ backgroundColor: 'lightgray' }}>
      <TableRow>
       
        <TableCell
          align={"center"}
          style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
        >
          Id
        </TableCell>
        <TableCell
          align={"center"}
          style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
        >
          Name
        </TableCell>
        <TableCell
          align={"center"}
          style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
        >
          Type
        </TableCell>
        <TableCell
          align={"center"}
          style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
        >
          text
        </TableCell>
        <TableCell
          align={"center"}
          style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
        >
          Category
        </TableCell>
        <TableCell
          align={"center"}
          style={{ top: 57, minWidth: 30, fontWeight: 'bold', fontSize: 12 }}
        >
          Language
        </TableCell>
        <TableCell

          align={"center"}
          style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
        >
          Status
        </TableCell>
        <TableCell

          align={"center"}
          style={{ top: 57, minWidth: 87, fontWeight: 'bold', fontSize: 12 }}
        >
          Body Text
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
        <TableRow tabIndex={-1} key={dataviewtemp.id}>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            {dataviewtemp.id}
          </TableCell>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            {dataviewtemp.name}
          </TableCell>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            {dataviewtemp.components?.[1]?.type}
          </TableCell>
          <TableCell align="center" style={{ minWidth: 87, fontSize: 12 }}>
            {dataviewtemp.components?.[1]?.text}
          </TableCell>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            {dataviewtemp.category}
          </TableCell>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            {dataviewtemp.language}
          </TableCell>
          <TableCell align="center" style={{ minWidth: 87, fontSize: 12 }}>
            <button
              style={{
                height: 20,
                color: 'white',
                borderRadius: '0.4rem',
                border: 'none',
                paddingLeft: 10,
                paddingRight: 10,
                backgroundColor: 'green',
              }}
            >
              {dataviewtemp.status}
            </button>
          </TableCell>
          <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
                {dataviewtemp.components?.[1]?.example?.body_text?.[0]?.map((item, index) => (
                    <div key={index}>{item}</div>
                ))}
            </TableCell>
          {/* <TableCell align="center" style={{ minWidth: 30, fontSize: 12 }}>
            {data.components?.[0]?.example?.body_text?.[0]}
          </TableCell> */}
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
          </DialogContentText>
        </DialogContent>
        <div style={{display:'flex',justifyContent:'space-around', width:'90%'}}>
        <p style={{fontWeight:'bold'}}>Placeholders :</p>
        {renderPlaceholders()}
        </div>
        <DialogActions>
          {/* <Button onClick={handleClose}>Close</Button> */}
          <MDBBtn  className='my-1' onClick={() => {
            handleClose()
            }} >cancel</MDBBtn>
          <MDBBtn outline className='my-1' onClick={() => {
            handleClose()
            handleSubmit()
            }} >Send</MDBBtn>
        </DialogActions>
      </Dialog>
    </React.Fragment>

      </Box>
      
    </div>
  )
}



